import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button } from '@mui/material';
import SearchBar from './search';
import {
  createFamilyMemberProfile,
  updateFamilyMemberProfile,
  updateIndependentFamilyMemberProfile,
} from '../../../actions/matrimonialAction';
import { getLoggedInUserId } from '../../../actions/authAction';
import LoadingSpinner from '../../loader';
import { toast } from 'react-toastify';
import { AppContext } from '../Context';
import AddIndependentUser from './addIndependentUser';
import { ExtractDataFromProfile } from './formatDisplayName';

const AddMemberForm = ({ onClose, editMemberProfile, editMemberMode }) => {
  const [graphNodeId, setGraphNodeId] = useState('');
  const [relation, setRelation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [qualification, setQualification] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [relationError, setRelationError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [showIndependentUserForm, setShowIndependentUserForm] = useState(false);
  const [independentUserData, setIndependentUserData] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [editMemberData, setEditMemberData] = useState({});

  const loggedInUserId = getLoggedInUserId();
  const { matrimonialId, handleChangeMultiValues } = useContext(AppContext);

  useEffect(() => {
    if (editMemberMode) {
      const { name, gautra, subcaste, village } = ExtractDataFromProfile(editMemberProfile);
      setEditMemberData({ name, gautra, subcaste, village });
      setIndependentUserData(editMemberProfile?.independent_profile_data);
      setGraphNodeId(editMemberProfile?.graph_node_id || '');
      const matchingProfile = editMemberProfile?.matrimonial_id_data?.find(
        (profile) => profile?.matrimonial_id === matrimonialId,
      );
      setRelation(matchingProfile?.relation || '');
      setOccupation(editMemberProfile?.occupation || '');
      setQualification(editMemberProfile?.qualification || '');
      if (editMemberProfile.contact_number && editMemberProfile.contact_number.length > 0) {
        setContactNumber(editMemberProfile.contact_number[0]);
      }
    }
  }, [editMemberProfile]);

  const handleSave = async () => {
    if (!graphNodeId) {
      if (!independentUserData.name) {
        setNameError(true);
        return;
      }
    }
    if (!relation) {
      setRelationError(true);
      return;
    }
    const createData = () => {
      const data = {
        created_by: loggedInUserId,
        matrimonial_id_data: [{ matrimonial_id: matrimonialId, relation: relation }],
        occupation,
        qualification,
        contact_number: [contactNumber],
      };
      if (graphNodeId) {
        data.graph_node_id = graphNodeId?.trim();
      } else if (independentUserData.name) {
        // data.independent_profile_data = independentUserData.;
        data.independent_profile_data = {
          name: independentUserData?.name?.trim() || '',
          father_name: independentUserData?.father_name?.trim() || '',
          village: independentUserData?.village?.trim() || '',
          gautra: independentUserData?.gautra?.trim() || '',
          subcaste: independentUserData?.caste?.trim() || '',
        };
      }
      return data;
    };
    const data = createData();

    try {
      setLoading(true);
      let result;
      if (editMemberMode) {
        if (editMemberProfile?.graph_node_id && graphNodeId) {
          result = await updateFamilyMemberProfile(matrimonialId, graphNodeId, data);
          console.log('Result from updateFamilyMemberProfile', result);
        } else if (editMemberProfile?.independent_profile_data) {
          result = await updateIndependentFamilyMemberProfile(editMemberProfile?._id, data);
          console.log('Result from updateIndependentFamilyMemberProfile', result);
        }
      } else {
        result = await createFamilyMemberProfile(data);
        console.log('Result from createFamilyMemberProfile', result);
      }
      if (result?.status) {
        if (editMemberMode) {
          toast.success('Family Member Updated Successfully');
        } else {
          toast.success('Family Member Added Successfully');
        }
      }

      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Error updating or creating family member profile', error);
      toast.error(`An error occurred while updating or creating the family member profile., ${error}`);
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '0 auto' }}>
      {loading ? (
        <LoadingSpinner message="Adding Family Mmember" />
      ) : (
        <>
          {nameError && <p style={{ color: 'red', marginTop: '5px' }}>Name is required</p>}{' '}
          <SearchBar
            editMemberData={editMemberData}
            setSelectedUser={(userData) => {
              if (userData) {
                setGraphNodeId(userData.uid);
                setNameError(false);
              }
            }}
            handleNoneOfTheseClick={(searchParams) => {
              // setGraphNodeId('');
              setShowIndependentUserForm(true);
              setSearchParams(searchParams);
            }}
            disable={editMemberMode}
          />
          {showIndependentUserForm && (
            <AddIndependentUser
              onClose={() => setShowIndependentUserForm(false)}
              showPhoneNoField={false}
              handleCreate={(data) => {
                setEditMemberData(data);
                setIndependentUserData(data);
                setShowIndependentUserForm(false); // Close the form when creating
              }}
              searchParams={searchParams}
            />
          )}
          <TextField
            label="Relation"
            fullWidth
            value={relation}
            onChange={(e) => {
              setRelation(e.target.value);
              setRelationError(false);
            }}
            error={relationError}
            helperText={relationError ? 'Relation is required' : ''}
          />
          <TextField label="Occupation" fullWidth value={occupation} onChange={(e) => setOccupation(e.target.value)} />
          <TextField
            label="Qualification"
            fullWidth
            value={qualification}
            onChange={(e) => setQualification(e.target.value)}
          />
          <TextField
            label="Contact Number"
            fullWidth
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </>
      )}
      <div>
        <Button
          variant="contained"
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            borderRadius: '100px',
            marginTop: '10px',
            marginRight: '10px', // Add some margin to separate buttons
          }}
          onClick={handleSave} // Call handleSave function when Save button is clicked
        >
          {editMemberMode ? 'Update' : 'Save'}
        </Button>
        <Button
          variant="contained"
          color="error" // Change button color to red for cancel button
          sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            borderRadius: '100px',
            marginTop: '10px',
          }}
          onClick={onClose} // Call onClose function from props when Cancel button is clicked
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddMemberForm;
